import Styles from "../Styles/footer.module.scss";
import instagram from '../Assets/insta.png';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={Styles.footer}>
      <a href="https://github.com/AlexBeesley" target="_blank" rel="noopener noreferrer">
        &copy; {currentYear} developed by Alex Beesley
      </a>
    </footer>
  );
}
