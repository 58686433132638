import Styles from '../Styles/grid.module.scss';
import React from 'react';
import { NavLink } from "react-router-dom";
import Popup from '../Components/popup';
import Footer from '../Components/footer';
import vid1 from '../Assets/vid1.mp4';
import vid2 from '../Assets/vid2.mp4';
import bitcoinQR from '../Assets/bitcoinQR.jpeg';
import ethereumQR from '../Assets/ethereumQR.jpeg';


export default function Grid() {
    const [showSocials, setShowSocials] = React.useState(false)
    const onClickSocials = () => setShowSocials(!showSocials)

    const [showCrypto, setShowCrypto] = React.useState(false)
    const onClickCrypto = () => setShowCrypto(!showCrypto)

    const [showWishlist, setShowWishlist] = React.useState(false)
    const onClickWishlist = () => setShowWishlist(!showWishlist)

    return (
        <>

            {showSocials ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickSocials}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContentalt}>
                        <a className={Styles.popupLink} href="https://www.tiktok.com/@jheanellecorine" target="_blank" rel="noreferrer" title="TikTok - @jheanellecorine">
                            <i className="fab fa-tiktok" />
                            <p className={Styles.text}>TikTok</p>
                        </a>
                        <a className={Styles.popupLink} href="https://www.reddit.com/user/JheanelleCorine" target="_blank" rel="noreferrer" title="Reddit - @JheanelleCorine">
                            <i className="fab fa-reddit-square" />
                            <p className={Styles.text}>Reddit</p>
                        </a>
                        <a className={Styles.popupLink} href="https://www.facebook.com/illuminatibyjc/" target="_blank" rel="noreferrer" title="Facebook - @illuminatibyjc">
                            <i className="fab fa-facebook-square" />
                            <p className={Styles.text}>Facebook</p>
                        </a>
                        <a className={Styles.popupLink} href="https://twitter.com/illuminatibyjc" target="_blank" rel="noreferrer" title="Twitter - @illuminatibyjc">
                            <i className="fab fa-twitter-square" />
                            <p className={Styles.text}>Twitter</p>
                        </a>
                    </div>
                </Popup> : null}

            {showCrypto ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickCrypto}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContentAlt}>
                        <div className={Styles.popupSectionAlt}>
                            <i className="fa-brands fa-bitcoin" />
                            <img src={bitcoinQR} />
                            <p>bc1qsqluqqj6ek3m2695p3nf9gjfhfmve602c0unra</p>
                        </div>
                        <div className={Styles.popupSectionAlt}>
                            <i className="fa-brands fa-ethereum" />
                            <img src={ethereumQR} />
                            <p>0x4211c158AB39d8A5E50a9c17D9c79Bb38C6B5a99</p>
                        </div>
                    </div>
                </Popup> : null}

            {showWishlist ?
                <Popup>
                    <button className={Styles.popupClose} onClick={onClickWishlist}>
                        <i className="fas fa-times" />
                    </button>
                    <div className={Styles.popupContent}>
                        <a className={Styles.popupLink} href="https://www.thingstogetme.com/372843accd88" target="_blank" rel="noreferrer" title="Thingstogetme - Tasha Black">Open Wishlist</a>
                        <p className={Styles.textAlt2}>With every item you send, you’ll receive a free shoutout, free custom pics, and/or free 5 minute custom video wearing your gift!</p>
                    </div>
                </Popup> : null}

            <div className={Styles.container}>
                <a className={Styles.one} href="https://www.youtube.com/@jheanellecorine" target="_blank" rel="noreferrer" title="YouTube - @jheanellecorine">
                    <div className={Styles.overlay} />
                    <div className={Styles.text}>Youtube</div>
                </a>
                <button className={Styles.two} onClick={onClickSocials}>
                    <div className={Styles.text}>Let's be friends</div>
                </button>
                <a className={Styles.three} href="https://illuminatibyjc.com/" target="_blank" rel="noreferrer" title="Illuminati on Shopify">
                    <video src={vid1} loop autoPlay muted playsInline />
                    <div className={Styles.overlay} />
                    <div className={Styles.text}>ILLUMINATI</div>
                </a>
                <a className={Styles.four} href="https://www.thingstogetme.com/372843accd88" target="_blank" rel="noreferrer" title="Tasha's Wishlist">
                    <div id={Styles.hide} className={Styles.text}>Wishlist</div>
                </a>
                <div className={Styles.five}>
                    <a className={Styles.text} href="https://cash.app/$BKStores" target="_blank" rel="noreferrer" title="CashApp - $BKStores">Cash App</a>
                    <a className={Styles.text} href="https://venmo.com/BKStores" target="_blank" rel="noreferrer" title="Venmo - @BKStores">Venmo</a>
                    <a className={Styles.text} onClick={onClickCrypto}>Crypto</a>
                    <a className={Styles.text} href="https://www.paypal.com/donate/?hosted_button_id=AB7CW8KPEH6LL" target="_blank" rel="noreferrer" title="PayPal - @BKStores">PayPal</a>
                </div>
                <a className={Styles.six} href="https://poshmark.com/closet/jheanellecorine" target="_blank" rel="noreferrer" title="POSHMARK Website">
                    <video src={vid2} loop autoPlay muted playsInline />
                    <div className={Styles.overlay} />
                    <div className={Styles.text}>Poshmark</div>
                </a>
                <a className={Styles.seven} href="https://www.instagram.com/jheanellecorine/" target="_blank" rel="noreferrer" title="Tasha's Instagram">
                    <div className={Styles.overlay} />
                    <div className={Styles.text}>Instagram</div>
                </a>
                <a className={Styles.eight} href="https://pin.it/3jhIE1b" target="_blank" rel="noreferrer" title="Tasha's Pintrest">
                    <div className={Styles.text}>Pinterest</div>
                </a>
            </div>
            <Footer />
        </>
    )
}