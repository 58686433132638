// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qQ47RSyDcDzDtoP37O4mZA\\=\\={font-size:.8rem;width:90%;position:absolute;bottom:.5rem;left:0;z-index:1}.qQ47RSyDcDzDtoP37O4mZA\\=\\= a{padding-left:.5rem;color:#fff;text-decoration:none;font-weight:bold;cursor:pointer}.qQ47RSyDcDzDtoP37O4mZA\\=\\= a:hover{color:#c00004}@media only screen and (max-width: 60rem){.qQ47RSyDcDzDtoP37O4mZA\\=\\={display:none}}", "",{"version":3,"sources":["webpack://./src/Styles/footer.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,SAAA,CACA,iBAAA,CACA,YAAA,CACA,MAAA,CACA,SAAA,CAEA,8BACE,kBAAA,CACA,UAAA,CACA,oBAAA,CACA,gBAAA,CACA,cAAA,CAGF,oCACE,aAAA,CAIJ,0CACE,4BACE,YAAA,CAAA","sourcesContent":[".footer {\r\n  font-size: 0.8rem;\r\n  width: 90%;\r\n  position: absolute;\r\n  bottom: 0.5rem;\r\n  left: 0;\r\n  z-index: 1;\r\n\r\n  a {\r\n    padding-left: .5rem;\r\n    color: white;\r\n    text-decoration: none;\r\n    font-weight: bold;\r\n    cursor: pointer;\r\n  }\r\n\r\n  a:hover {\r\n    color: #c00004;\r\n  }\r\n}\r\n\r\n@media only screen and (max-width: 60rem) {\r\n  .footer {\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "qQ47RSyDcDzDtoP37O4mZA=="
};
export default ___CSS_LOADER_EXPORT___;
